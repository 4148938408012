import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { isBoxed, unbox } from 'ngrx-forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IEntity } from 'src/models/entity.model';
import { ApiService } from './api.service';

export interface IEntityResult<TEntity extends IEntity> {
  data: TEntity;
}

export interface IEntityListResult<TEntity extends IEntity> {
  data: TEntity[];
}

export interface IEntityErrorResponse {
  error: { error: string; success: boolean };
}

@Injectable()
export abstract class EntityService<TEntity extends IEntity> extends ApiService {
  protected abstract entityName: string;

  constructor(http: HttpClient) {
    super(http);
  }

  public getAll(): Observable<TEntity[]> {
    return this.get<IEntityListResult<TEntity>>([this.entityName], null).pipe(map((result) => result.data));
  }

  protected ensureIsUnboxed<T>(element: T): T {
    if (element == null) {
      return null;
    }

    const newElement = { ...element };

    for (let attributeName in newElement) {
      if (isBoxed(newElement[attributeName])) {
        newElement[attributeName] = unbox(newElement[attributeName]) as any;
      } else if (_.isObject(newElement[attributeName])) {
        newElement[attributeName] = this.ensureIsUnboxed(newElement[attributeName]);
      }
    }

    return newElement;
  }
}
