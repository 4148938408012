import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable()
export class UpdateService {
  constructor(private appRef: ApplicationRef, private serviceWorker: SwUpdate) {}

  public checkForUpdates(minutes: number): void {
    if (!this.serviceWorker.isEnabled) {
      return;
    }

    const appIsStable = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    const everyMinutes = interval(minutes * 60 * 1000);

    concat(appIsStable, everyMinutes).subscribe(() => this.serviceWorker.checkForUpdate());

    this.serviceWorker.available.subscribe(() => this.serviceWorker.activateUpdate());
    this.serviceWorker.activated.subscribe(() => window.location.reload());
  }
}
