import { HttpClientModule } from '@angular/common/http';
import { NgModule, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { StartingComponent } from 'src/components/loading/loading.component';
import { IPagePathWindow } from 'src/models/pagepath.model';
import { PagePathApiService } from 'src/services/api/pagepath.service';
import { UpdateService } from 'src/services/utility/update.service';

declare const window: IPagePathWindow;

@NgModule({
  declarations: [StartingComponent],
  imports: [BrowserModule, HttpClientModule],
  providers: [UpdateService, PagePathApiService],
  bootstrap: [StartingComponent],
})
export class LoadingModule {
  constructor(zone: NgZone, pagePathApiService: PagePathApiService) {
    combineLatest([pagePathApiService.getAll(), import('./shop.module')])
      .pipe(
        map(([pagePaths, module]) => {
          window.pagePaths = pagePaths;
          zone.runOutsideAngular(() => platformBrowserDynamic().bootstrapModule(module.ShopModule));
        })
      )
      .subscribe();
  }
}
