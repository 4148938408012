import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export abstract class BaseService {
  protected combineRootWithPath(path: string[]): string {
    return this.pathJoin([`${environment.serverUrl}:${environment.serverPort}`, environment.apiUrl, ...path]);
  }

  protected pathJoin(parts: string[]): string {
    return parts.filter((x) => x != null && x.trim() !== '').join('/');
  }
}
