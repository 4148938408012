import { IEnvironment } from 'src/models/environment.model';

export const environment: IEnvironment = {
  production: false,
  serviceWorker: true,
  googleMapsApiKey: 'AIzaSyAAIdBCeOGsJmYlMhVCXbFX_hMwSHs0kAA',
  googleTagManagerId: 'GTM-KJHNT7B',
  serverUrl: 'https://api.test.ab-events.group',
  serverPort: 443,
  apiUrl: 'api/v1',
  projectId: 'pr33',
};
