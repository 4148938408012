<div class="loading">
  <div class="logo">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 50">
      <defs>
        <style>
          .cls-1 {
            fill: #aeadae;
          }
        </style>
      </defs>
      <g>
        <path
          class="cls-1"
          d="M48.36,18.62v-.3c3.55-.7,5.85-3.75,5.85-7.75,0-6.5-4.5-10.25-12.3-10.25H25.45L0,36.93v.9H8.75L27,10.67h.1V23.16h14V20.42l7.41,6.16-7.41,6.15V30h-14v7.84H41.51c8.55,0,13.4-3.85,13.4-10.5C54.91,22.82,52.36,19.37,48.36,18.62Zm-7.1-3.2H34.83V7h6.63c3.6,0,5.1,1.25,5.1,4.15S44.81,15.42,41.26,15.42Z"
        />
      </g>
    </svg>
  </div>
  <h1>AB Events</h1>
  <h2>Seite wird geladen</h2>
</div>
