import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPagePath } from 'src/models/pagepath.model';
import { EntityService } from '../entity.service';

@Injectable()
export class PagePathApiService extends EntityService<IPagePath> {
  protected entityName = 'page-paths';

  constructor(http: HttpClient) {
    super(http);
  }
}
