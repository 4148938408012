import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable()
export abstract class ApiService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  public get<TResponse>(path: string[], headers?: HttpHeaders, params?: any): Observable<TResponse> {
    return this.http.get<TResponse>(this.combineRootWithPath(path), { headers, params });
  }

  public post<TResponse, TBody>(path: string[], body: TBody, headers?: HttpHeaders, params?: any): Observable<TResponse> {
    return this.http.post<TResponse>(this.combineRootWithPath(path), body, { headers, params });
  }
}
